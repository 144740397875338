<template >
  <!-- <v-layout row> -->
  <!-- <v-flex xs4> </v-flex> -->
  <!-- <v-flex xs4 class="grey lighten-4"> -->
  <!-- <v-container style="position: relative; top: 50%" class="text-xs-center"> -->

  <v-container style="position: relative; top: 10%" class="text-lg-center">
    <v-row>
      <v-col cols="5" sm="5" md="5" lg="5"></v-col>      
      <v-col cols="2" sm="2" md="2" lg="2">
        <v-img           
          src="@/assets/ypogeiaathinalogo.jpg">
        </v-img>        
      </v-col>      
      <v-col cols="5" sm="5" md="5" lg="5"></v-col>      
    </v-row>
    <v-row>
      <v-col cols="1" sm="3" md="3"></v-col>      
      <v-col cols="1" sm="6" md="6">                
        <v-card elevation="5">
          <v-card-title primary-title>
            <h4>Vivlio Panos Login</h4>
          </v-card-title>          
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(login)">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="username"
                  :rules="{
                    regex: /^[A-Za-z0-9!@#$%]*$/,
                    required: true,
                    max: 15,
                  }"
                >
                  <v-text-field
                    v-model="username"
                    :error-messages="errors"
                    prepend-icon="mdi-account"
                    name="Username"
                    label="Username"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  :rules="{
                    regex: /^[A-Za-z0-9!@#\$%]*$/,
                    required: true,
                    max: 10,
                    min: 4,
                  }"
                >
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    prepend-icon="mdi-lock"
                    name="Password"
                    label="Password"
                    type="password"
                    autocomplete="on"
                  ></v-text-field>
                </ValidationProvider>
                <v-card-actions>
                  <v-btn
                    :loading="loadingLogin"
                    color="rgb(102, 102, 51, 0.5)"
                    type="submit"
                    primary
                    large
                    block
                    >Login</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1" sm="3" md="3"></v-col>
    </v-row>
  </v-container>
</template>

<script>

import { required, max, min, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode,} from "vee-validate";


setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} cannot be empty",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
}),
  extend("min", {
    ...min,
    message: "{_field_} may not be less than {length} characters",
  }),
  extend("regex", {
    ...regex,
    message: "{_field_} can contain only letters, numbers and symbols(!@#$%)",
  });

export default {
  name: "login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loadingLogin: false,
    username: "",
    password: "",         
  }),
  methods: {
    async login() {
      this.loadingLogin = !this.loadingLogin;      
      try {
        await this.$store.dispatch('user/authenticate', {
          username: this.username,
          password: this.password,
        });
        this.loadingLogin = !this.loadingLogin;
        this.$router.push("/customers");
      } catch (error) {
        this.loadingLogin = !this.loadingLogin;
      }
    },
  },
};
</script>

<style scoped>
.cover {
  height: 300px;
}
</style>