<template>
  <div class="mt-1">
    <NavBar></NavBar>

    <v-container>

      <v-row justify="center" align="center">
        <v-col cols="12">
          <h4 style='text-align: center'>Παναγιώτης Δευτεραίος: Υπόγεια Αθήνα - τα μυστικά μιας άγνωστης πόλης, σελίδες
            656, σχήμα 17 x 24 <br>
            Για παραγγελία του βιβλίου συμπληρώστε τα στοιχεία σας για να λάβετε το βιβλίο υπογεγραμμένο, με ιδιόχειρη,
            προσωπική αφιέρωση από τον συγγραφέα</h4>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">

        <v-col cols="12" lg="3">
          <div class="image-container">
          <img src="@/assets/logos/vivliomprosta.jpg" class="responsive-image" alt="Left Image" />
        </div>
        </v-col>

        <v-col cols="12" lg="6">
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-text-field label="Όνομα" dense :rules="myRules.selectField" required
                  v-model="selectedCustomer.firstName" name="selectedCustomerFirstName" filled>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Επίθετο" dense :rules="myRules.selectField" required filled
                  v-model="selectedCustomer.lastName" name="selectedCustomerLastName">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-text-field label="Email" dense :rules="myRules.selectField" filled v-model="selectedCustomer.email"
                  name="selectedCustomerEmail">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Κινητό Τηλέφωνο" dense filled :rules="myRules.selectField"
                  v-model="selectedCustomer.mobilePhone" name="selectedCustomerMobilePhone">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-select filled required :rules="myRules.selectField" v-model="selectedCustomer.troposexoflisis" 
                :items="troposexoflisis" chips
                  label="Επιλογή τρόπου εξόφλησης" dense return-object>
                </v-select>
              </v-col>
            </v-row>

            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-textarea label="Στοιχεία παράδοσης του βιβλίου, πόλη, όροφος, κουδούνι και άλλες, λοιπές σημειώσεις"
                  rows="9" filled v-model="selectedCustomer.notes" :counter="255"></v-textarea>
              </v-col>

              <v-col cols="6">
                <v-text-field label="Αριθμός Αντιτύπων" dense type="number" filled :rules="myRules.noAntitypwn"
                  v-model="selectedCustomer.noAntitipwn"></v-text-field>
                <v-text-field label="Διεύθυνση Αποστολής" dense filled :rules="myRules.address"
                  v-model="selectedCustomer.address"></v-text-field>
                <v-text-field label="Ταχυδρομικός Κώδικας (ΤΚ)" dense type="number" filled
                  v-model="selectedCustomer.taxkwd"></v-text-field>
                <h4>Μετά το αίτημα θα λάβετε email ενημέρωσης από την προσωπική ηλεκτρονική διεύθυνση του συγγραφέα:
                  p.defteraios@windowslive.com με το ακριβές ποσό
                  που πρέπει να κατατεθεί, ανάλογα με τα έξοδα αποστολής. Αφού κατατεθεί το ακριβές ποσό στο iban που θα σας γνωστοποιηθεί μέσω του email, 
                  αποστέλλεται το βιβλίο.</h4>
              </v-col>
            </v-row>

            <v-row justify="center" align="center">
              <v-btn v-if="this.$route.params.studentId != 0" @click="saveCustomer()" color="warning"
                :loading="this.loading" :disabled="!valid || hasVoted">ΑΠΟΣΤΟΛΗ</v-btn>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="12" lg="3">
          <div class="image-container">
          <img src="@/assets/logos/vivliopisw.jpg" class="responsive-image" alt="Right Image" />
        </div>
        </v-col>
        
      </v-row>

      <v-row justify="center" align="center" class="mt-10">
        <div class="justify-center text-center">
          <a href="https://fiberzy.com" target="_blank">
            <v-img contain max-height="100" src="@/assets/logos/fiberzy.png"></v-img>
          </a>
        </div>
      </v-row>
      <v-row justify="center" align="center" class="mt-5">
        <div class="text-center">
          <div style="border-bottom: solid red 2px;"><i>* Αυτή η ιστοσελίδα δημιουργήθηκε από την <a style="color:blue"
                href="https://fiberzy.com" target="_blank">FiberZy</a>. Χτίσε και εσύ την επόμενη ψηφιακή σου ιδέα μαζί
              μας...</i></div>
        </div>
      </v-row>

      <br>

    </v-container>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions } from "vuex";
import swal from "sweetalert";

export default {
  components: {
    NavBar
  },

  data: () => ({
    hasVoted: false,
    menu: false,
    loading: false,
    selectedCustomer: {
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
      tripDate: "",
    },
    valid: false,
    troposexoflisis: ['Προεξόφληση με κατάθεση του ποσού 21 € + έξοδα αποστολής σε λογαριασμό της Τράπεζας Πειραιώς',
    'Προεξόφληση με κατάθεση του ποσού 21 € + έξοδα αποστολής μέσω IRIS Payments'],
    myRules: {
      allFields: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
      selectField: [(v) => v.length > 0 || "Το πεδίο είναι υποχρεωτικό"],
    },
  }),  

  methods: {
    ...mapActions("customers", ["createCustomer"]),

    async saveCustomer() {
      try {
        this.loading = true;
        await this.createCustomer(this.selectedCustomer);
        swal(
          "Επιτυχής αποστολή μηνύματος. Παρακαλώ μπορείτε να κλείσετε την συγκεκριμένη σελίδα."
        );
        this.loading = false;
      } catch {
        this.loading = false;
        swal(
          "Υπήρξε κάποιο πρόβλημα. Παρακαλώ επικοινωνήστε με τον διαχειριστή της σελίδας."
        );
      }
      this.hasVoted = true;
      // this.$router.push("/students");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },

  computed: {    
    formatedDate: {
      get: function () {
        return null;
      },
      set: function (val) {
        return (this.selectedCustomer.tripDate = this.formatDate(val));
      },
    },
  },
};
</script>


<style scoped>
.image-container {
  /* Set the maximum width of the container */
  max-width: 100%;
  /* Center the image horizontally */
  margin: 0 auto;
}

.responsive-image {
  /* Set the maximum width of the image to 100% */
  max-width: 100%;
  /* Allow the height to adjust automatically */
  height: auto;
  /* Ensure the image doesn't exceed its container's width */
  display: block;
  /* Center the image within its container vertically, if needed */
  margin: 0 auto;
}
</style>

