import axios from 'axios';
import store from '@/state/store.js'

export default {
    namespaced: true,
    state: {
        allTrips: [],
        allTripsByActive: [],
        selectedCustomer: {},
        newTrip: {},
    },

    mutations: {
        setTrips(state, allTrips) {
            state.allTrips = allTrips;
        },
        setSelectedTrip(state, selectedTrip) {
            state.selectedTrip = selectedTrip;
        },
        setNewTrip(state, newTripDetails) {
            state.newTrip = newTripDetails;
        },
        setTripsByActive(state, allTripsByActive) {
            state.allTripsByActive = allTripsByActive;
        },
        updateTrip(state, updatedTripData) {
            state.selectedTrip = updatedTripData;
        }
    },

    actions: {

        fetchAllTripsAction: ({ commit }) => {
            return new Promise((resolve, reject) => {
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/trips")
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setTrips", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fetchTripByIdAction: ({ commit }, tripId) => {
            return new Promise((resolve, reject) => {
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .get("/trips/trip?tripId=" + tripId)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit("setSelectedTrip", data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createTrip({ commit }, newTripDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/trips', newTripDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('setNewTrip', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updateTripAction({ commit }, tripDetails) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.token
                axios
                    .post('/trips', tripDetails)
                    .then(({ data, status }) => {
                        if (status === 200) {
                            commit('updateTrip', data);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

    },

    getters: {
        getAllTrips: state => state.allTrips,
        getAllTripsByActive: state => state.allTripsByActive,
        getSelectedTrip: state => state.selectedTrip
    },
}