<template>
  <div>
    <v-container justify="center" align="center">
      <v-app-bar app height="90" flat dark color="orange">
        <v-row>
          <v-col cols="12" lg="6" sm="12" md="6">
            <v-toolbar-items>              
                <h2>ΥΠΟΓΕΙΑ ΑΘΗΝΑ</h2>
            </v-toolbar-items>
          </v-col>
          <v-spacer> </v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
              v-if="this.$store.state.user.token"
              plain
              x-large
              class="secondary--text"
              :ripple="false"
              text
              :to="'/trips'"
              >ΕΚΔΡΟΜΕΣ
            </v-btn>
            <v-btn
              v-if="this.$store.state.user.token"
              plain
              x-large
              class="secondary--text"
              :ripple="false"
              text
              :to="'/customers'"
              >ΠΕΛΑΤΕΣ
            </v-btn>
            <v-btn
              v-if="this.$store.state.user.token"
              plain
              x-large
              class="secondary--text"
              :ripple="false"
              text
              @click="logout()"
              >ΑΠΟΣΥΝΔΕΣΗ
            </v-btn>
            <!-- <v-btn> v.2.2 </v-btn> -->
          </v-toolbar-items>
        </v-row>
      </v-app-bar>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "navbar",
  components: {},
  data: () => ({
    links: [
      { title: "ΠΕΛΑΤΕΣ" },
      // { title: "ΕΚΔΡΟΜΕΣ" },
      // { title: "ΚΑΤΕΥΘΥΝΣΕΙΣ" },
      // { title: "ΠΛΑΝΟ" },
      { title: "ΑΠΟΣΥΝΔΕΣΗ" },
    ],
    drawer: false,
    false: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    ...mapActions("user", ["destroyToken"]),

    logout() {
      this.destroyToken().then(() => {
        this.$router.push("/login").catch(() => {});
      });
    },

    forward(pageToMove) {
      if (pageToMove === "ΑΠΟΣΥΝΔΕΣΗ") {
        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => {});
        });
      } else if (pageToMove === "ΠΕΛΑΤΕΣ") {
        this.$router.push("/customers").catch(() => {});
      } else if (pageToMove === "ΕΚΔΡΟΜΕΣ") {
        this.$router.push("/trips").catch(() => {});
      } else if (pageToMove === "ΠΛΑΝΟ") {
        this.$router.push("/plans").catch(() => {});
      } else if (pageToMove === "ΚΑΤΕΥΘΥΝΣΕΙΣ") {
        this.$router.push("/majors").catch(() => {});
      }
    },
  },
  computed: {},
};
</script>

<style >
.v-btn--active .v-btn__content {
  color: whitesmoke;
}
</style>